<template>
  <li
    class="nav-item has-sub"
    :class="{
      open: isOpen,
      disabled: item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
      class="d-flex align-items-center"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <feather-icon v-if="item.icon" :icon="item.icon || 'CircleIcon'" />
      <b-img
        class="mr-1"
        v-if="item.customIcon"
        :src="require(`@/assets/images/icons/${item.customIcon}`)"
      />
      <span class="menu-title text-truncate">{{ item.title }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse, BImg } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
// import { ref } from '@vue/composition-api'
// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'
export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
    BImg,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)
    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    }
  },
}
</script>

<style>
.main-menu .shadow-bottom {
  display: none !important;
}
.main-menu.menu-light .navigation,
.navbar-header {
  background-color: hsl(0deg 0% 12.16%);
}
.main-menu.menu-light .navigation li a > * {
  color: #fff;
  font-weight: 400;
    font-size: 15px;
}
.main-menu.menu-light .navigation li ul a > *{
  color: #fff;
  font-weight: 400;
    font-size: 13px;
}
.main-menu.menu-light .navigation li:hover {
  background-color: #2d3d52;
}
.menu-content {
  background-color: #2d3d52;
}
.main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background-color: #2d3d52;
}
.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,
.main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background-color: #2d3d52;
}
.menu-content li {
  /* padding-left: 20px; */
  font-size: 15px;
}
.main-menu.menu-light .navigation > li ul li ul a {
  font-size: 13px !important;
  padding: 15px 15%;
  color: #aac4c5 !important;
}
[dir] .main-menu.menu-light .navigation > li ul .open > a,
[dir] .main-menu.menu-light .navigation > li ul .sidebar-group-active > a {
  background-color: #1a2226;
}
.main-menu.menu-light .navigation > li > ul li:first-of-type {
  padding: 6px 0 !important;
}
.main-menu.menu-light .navigation > li ul .open > ul,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > ul {
  width: 100% !important;
}
.main-menu.menu-light .navigation > li ul .open > ul {
  background-color: #1c253c;
}
.main-menu.menu-light .navigation > li > ul li {
  margin: 0px !important;
}
.main-menu.menu-light .navigation > li ul .active {
  background: #1c253c;
}
.main-menu.menu-light .navigation > li.active > a {
  background: #1c253c;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  background-color: #2d3d52 !important;
}
</style>
