export default [
  {
    header: 'Apps & Pages',
    per: 'header',
  },
  {
    title: 'Home',
    route: 'Home',
    customIcon: 'Dashboard.svg',
    per: 'home',
  },
  {
    title: 'Products Main Table',
    customIcon: 'list-search.svg',
    route: 'products-main-table',
    per: 'show',
    per2: 'variant',
  },
  {
    title: 'Ads Main Table',
    customIcon: 'list-search.svg',
    route: 'ads-main-table',
    per: 'show',
    per2: 'variant-ads',
  },
  {
    title: 'Active Ads Main Table',
    customIcon: 'list-search.svg',
    route: 'ads-active-main-table',
    per: 'show',
    per2: 'variant-ads',
  },
  {
    title: 'Ranked Items',
    customIcon: 'list-tree.svg',
    route: 'top-products',
    per: 'show',
    per2: 'variant',
  },
  {
    title: 'Reports List',
    route: 'Diagnostic-Reports',
    customIcon: 'Diagnostics.svg',
    per: 'show',
    per2: 'diagnostic',
  },
  {
    title: 'Products Managment',
    customIcon: 'products-new.svg',
    per: 'show',
    per2: 'product',
    children: [
      {
        title: 'Add General Products',
        route: 'AddGeneralProduct',
        icon: 'SmartphoneIcon',
        per: 'show',
        per2: 'product',

        
      },
      {
        title: 'New Products',
        icon: 'SmartphoneIcon',
        per: 'show',
        per2: 'product',
        children: [
          {
            title: 'Products List',
            route: 'NewProductIndex',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'product',
          },
          // {
          //   title: 'Add New Product',
          //   route: 'AddNewProduct',
          //   customIcon: 'plus.svg',
          //   per: 'create',
          //   per2: 'product',
          // },
          // {
          //   title: 'Add In Store Product',
          //   route: 'AddNewProduct-InStore',
          //   customIcon: 'plus.svg',
          //   per: 'create',
          //   per2: 'product',
          // },
        ],
      },
      {
        title: 'Used Products',
        icon: 'SmartphoneIcon',
        per: 'show',
        per2: 'product',
        children: [
          {
            title: 'Products List',
            route: 'ProductIndex',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'product',
          },
          // {
          //   title: 'Add Used Product',
          //   route: 'AddProduct',
          //   customIcon: 'plus.svg',
          //   per: 'create',
          //   per2: 'product',
          // },
          // {
          //   title: 'Add Product In Store',
          //   route: 'AddProduct-InStore',
          //   customIcon: 'plus.svg',
          //   per: 'create',
          //   per2: 'product',
          // },
          // {
          //   title: 'Add Unactive Product',
          //   route: 'AddUnactiveProduct',
          //   customIcon: 'plus.svg',
          //   per: 'create',
          //   per2: 'product',
          // },
        ],
      },



    ],
  },

  {
    header:
      'ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
    per: 'header',
    per2: 'home',
  },
  {
    title: 'Order Management',
    customIcon: 'list-search.svg',
    per: 'show',
    per2: 'order',
    children: [
      {
        title: 'Orders List',
        route: 'OrderIndex',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'order',
      },
      {
        title: 'Completed orders List',
        route: 'OrderCompletedIndex',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'order',
      },
      {
        title: 'AirWayBill List',
        route: 'IndexAirWayBill',
        customIcon: 'list-tree.svg',
        per: 'show',
        per2: 'air-way-bill',
      },
      {
        title: 'Create AWB & Pickup',
        route: 'CreateAirWayBill',
        customIcon: 'plus.svg',
        per: 'show',
        per2: 'create-air-way-bill',
      },
      {
        title: 'Orders Archived Data',
        route: 'OrderTrashed',
        customIcon: 'list-tree.svg',
        per: 'show-trashed',
        per2: 'order',
      },

    ],
  },
  {
    title: 'Payment Sessions Management',
    customIcon: 'list-search.svg',
    per: 'show',
    per2: 'payment-session',
    children: [
      {
        title: 'Payment Sessions List',
        route: 'paymentSession-index',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'payment-session',
      },

      {
        title: 'Completed Payment Sessions List',
        route: 'paymentSession-completed-index',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'payment-session',
      },
      {
        title: 'Payment Sessions Archived List',
        route: 'paymentSession-trashed-index',
        customIcon: 'list-tree.svg',
        per: 'show-trashed',
        per2: 'payment-session',
      },


    ],
  },


  {
    title: 'Subscriptions',
    customIcon: 'list-search.svg',
    per: 'subscriptions',
    per2: 'subscriptions',
    children: [

      {
        title: 'Subscriptions List',
        route: 'index-subscriptions',
        customIcon: 'list.svg',
        per: 'subscriptions',
        per2: 'show-subscriptions',
      },

    ],
  },
  {
    title: 'Bidding List',
    route: 'biddings-request-index',
    customIcon: 'list-search.svg',
    per: 'show',
    per2: 'bidding',
  },
  {
    title: 'Transaction List',
    route: 'Transaction',
    customIcon: 'list-search.svg',
    per: 'show',
    per2: 'payment',
  },
  {
    title: 'Refund List',
    route: 'Refund-Index',
    customIcon: 'list-search.svg',
    per: 'show',
    per2: 'refund',
  },
  {
    header:
      'ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
    per: 'header',
    per2: 'show',
    per3: 'show',
  },
  {
    title: 'Users Management',
    customIcon: 'users-list.svg',
    per: 'show',
    per2: 'active-cart' || 'user',
    children: [
      {
        title: 'Users List',
        customIcon: 'list.svg',
        route: 'Users-Index',
        per: 'show',
        per2: 'user',
      },
      {
        title: 'Active Carts',
        customIcon: 'list-tree.svg',
        route: 'Cart-Active',
        per: 'show',
        per2: 'active-cart',
      },
      {
        title: 'Add New User',
        customIcon: 'plus.svg',
        route: 'User-Add',
        per: 'create',
        per2: 'user',
      },

    ],
  },
  {
    title: 'Sellers Management',
    customIcon: 'vendors.svg',
    per: 'show',
    per2: 'vendor',
    children: [
      {
        title: 'Sellers List',
        route: 'Vendor-Index',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'vendor',
      },
      {
        title: 'Active Sellers List',
        route: 'Active-Vendor-Index',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'vendor',
      },
      {
        title: 'Add New Seller',
        route: 'Seller-Add',
        customIcon: 'plus.svg',
        per: 'create-vendor',
        per2: 'vendor',
      },
      {
        title: 'Sellers Wallet Summary',
        route: 'Vendor-Wallets-Index',
        customIcon: 'list-tree.svg',
        per: 'show',
        per2: 'vendor_wallet',
      },

    ],
  },
  {
    header:
      'ــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
    per: 'header',
    per2: 'admin',
    per3: 'user',
    per4: 'vendor',
  },
  {
    title: 'Finance',
    customIcon: 'report-money.svg',
    per: 'show',
    per2: 'finance',
    children: [
      {
        title: 'Payouts',
        route: 'Payouts-Index',
        icon: 'DollarSignIcon',
        per: 'show',
        per2: 'payouts',
      },
      {
        title: 'Company Financial Account',
        route: 'Company-Financial-Account',
        icon: 'DollarSignIcon',
        per: 'show',
        per2: 'financial',
      },
      {
        title: 'Sales Report',
        route: 'Sales Report Transaction Level',
        customIcon: 'list-tree.svg',
        per: 'show',
        per2: 'sales_report',
      },


    ],
  },
  {
    title: "Buyer's Guide",
    customIcon: 'mobile.svg',
    per: 'show',
    per2: 'phone_guide',
    children: [
      {
        title: 'Guides List',
        route: 'PhoneGuide',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'phone_guide',
      },
      {
        title: 'Guides Card View',
        route: 'PhoneGuideEcommerce',
        customIcon: 'list-tree.svg',
        per: 'show',
        per2: 'phone_guide',
      },
      {
        title: 'Create Item',
        route: 'CreateItem',
        customIcon: 'plus.svg',
        per: 'create-phone-guide',
        per2: 'phone_guide',
      },
    ],
  },

  {
    title: 'Settings',
    customIcon: 'Setting.svg',
    per2: 'setting',
    per: 'show',
    children: [


      {
        title: 'Payment Provider',
        route: 'PaymentProviders-Index',
        customIcon: 'list.svg',
        per: 'payment-providers',
        per2: 'show',
      },
      {
        title: 'Settings List',
        route: 'Settings-Index',
        customIcon: 'list-tree.svg',
        per: 'show',
        per2: 'setting',
      },
      {
        title: 'Add New Setting',
        route: 'Settings-Add',
        customIcon: 'plus.svg',
        per: 'create_setting',
        per2: 'setting',
      },


    ],
  },
  {
    title: 'Problems',
    route: 'Problems-Index',
    customIcon: 'list-search.svg',
    per: 'show',
    per2: 'problem',
  },

  {
    title: 'Marketing Compagins',
    customIcon: 'mail-cog.svg',
    per: 'newsletters',
    per2: 'variant',
    children: [
      {
        title: 'Send Messages',
        route: 'Send-newsletters',
        customIcon: 'mail-open.svg',
        per: 'newsletters',
        per2: 'send-newsletters',
      },
      {
        title: 'Compagins List',
        route: 'Show-newsletters',
        customIcon: 'mail-plus.svg',
        per: 'newsletters',
        per2: 'show-newsletters',
      },
    ],
  },
  {
    title: 'Operation Actions',
    customIcon: 'AccountReport.svg',
    per: 'show',
    per2: 'operation-actions',
    children: [
      {
        title: 'Order Actions',
        route: 'Operation-Order-Action',
        customIcon: 'list-tree.svg',
        per: 'operation-actions',
        per2: 'operation-order-actions',
      },
      {
        title: 'Payment Session Actions',
        route: 'Operation-PaymentSession-Action',
        customIcon: 'list-tree.svg',
        per: 'operation-actions',
        per2: 'operation-paymentSession-actions',
      },
      {
        title: 'Bidding Requestes Actions',
        route: 'Operation-Bidding-Action',
        customIcon: 'list-tree.svg',
        per: 'operation-actions',
        per2: 'operation-bidding-actions',
      },
      {
        title: 'Product Actions',
        route: 'Operation-Variant-Action',
        customIcon: 'list-tree.svg',
        per: 'operation-actions',
        per2: 'operation-product-actions',
      },
      {
        title: 'Refund Actions',
        route: 'Operation-Refund-Action',
        customIcon: 'list-tree.svg',
        per: 'operation-actions',
        per2: 'operation-refund-actions',
      },
      {
        title: 'Diagnostic Actions',
        route: 'Operation-Diagnostic-Action',
        customIcon: 'list-tree.svg',
        per: 'operation-actions',
        per2: 'operation-diagnostic-actions',
      },
      
      {
        title: 'Activity Log',
        customIcon: 'list-tree.svg',
        route: 'Activity-Log',
        per: 'show',
        per2: 'activity_log',
      },

    ],
  },
  {
    title: 'Archived Data',
    customIcon: 'archive.svg',
    per: 'show',
    per2: 'show-trashed',
    children: [

      {
        title: 'Bidding Archived List',
        route: 'biddings-request-archived-index',
        customIcon: 'list-tree.svg',
        per: 'show-trashed',
        per2: 'bidding',
      },
      {
        title: 'Subscriptions Archived List',
        route: 'trashed-subscriptions',
        customIcon: 'list-tree.svg',
        per: 'show-trashed',
        per2: 'subscriptions',
      },
      {
        title: 'Users Archived List',
        customIcon: 'list-tree.svg',
        route: 'Users-Trashed',
        per: 'show-trashed',
        per2: 'permission',
      },
      {
        title: 'Seller Archived List',
        route: 'Seller-Index-Trashed',
        customIcon: 'list-tree.svg',
        per: 'show-trashed',
        per2: 'vendor',
      },
      {
        title: 'Guides Archived List',
        route: 'Trashed-PhoneGuide',
        customIcon: 'list-tree.svg',
        per: 'show-trashed',
        per2: 'phone_guide',
      },
      {
        title: 'Archived Variants Main Table',
        customIcon: 'list-tree.svg',
        route: 'products-table-archived',
        per: 'show-trashed',
        per2: 'variant',
      },
      {
        title: 'Archived Variants Ads Main Table',
        customIcon: 'list-tree.svg',
        route: 'ads-table-archived',
        per: 'show-trashed',
        per2: 'variant',
      },
    ],
  },
  {
    title: 'Advacned Settings',
    customIcon: 'Setting.svg',
    per: 'advanced_setting',
    per2: 'show',
    children: [
      {
        title: 'Administrations',
        customIcon: 'Admins.svg',
        per: 'show',
        per2: 'admin',
        children: [
          {
            title: 'Admins List',
            route: 'Admins-Index',
            customIcon: 'list.svg',
            per: 'admin',
            per2: 'admin',
            children: [
              {
                title: 'Admins List',
                route: 'Admins-Index',
                customIcon: 'list-tree.svg',
                per: 'show',
                per2: 'admin',
              },
              {
                title: 'Add New Admin',
                route: 'Register-Admin',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'admin',
              },
              {
                title: 'Admins Archived Data',
                route: 'Admins-Trashed',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'admin',

              },
            ]
          },

          {
            title: 'Roles',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'admin',
            children: [
              {
                title: 'Roles List',
                route: 'Roles-list',
                customIcon: 'list-tree.svg',
                per: 'show',
                per2: 'role',
              },
              {
                title: 'Add New Role',
                route: 'Add-Role',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'role',
              },
            ],
          },
          {
            title: 'Permissions',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'permission',
            children: [
              {
                title: 'Permissions List',
                route: 'Permissions-list',
                customIcon: 'list-tree.svg',
                per: 'show',
                per2: 'permission',
              },
              {
                title: 'Add New Permission',
                route: 'Add-Permission',
                customIcon: 'plus.svg',
                per: 'show',
                per2: 'create',
              },
            ],
          },

        ],
      },
      {
        title: 'Accessories',
        customIcon: 'Accessories.svg',
        per: 'show',
        per2: 'accessory',
        children: [
          {
            title: 'Accessories List',
            route: 'Accessory-Index',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'accessory',
          },
          {
            title: 'Add Accessory',
            route: 'Accessory-Add',
            customIcon: 'plus.svg',
            per: 'create',
            per2: 'accessory',
          },
          {
            title: 'Accessories Archived Data',
            route: 'Accessory-Trashed',
            customIcon: 'list-tree.svg',
            per: 'show-trashed',
            per2: 'accessory',
          },

        ],
      },

      {
        title: 'Classifications',
        customIcon: 'tree.svg',
        per: 'show',
        per2: 'classifications',

        children: [
          {
            title: 'Categories',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'category',
            children: [
              {
                title: 'Categories List',
                route: 'Categories-list',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'category',
              },
              {
                title: 'Add New Category',
                route: 'Add-Category',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'category',
              },
              {
                title: 'Categories Archived Data',
                route: 'Trashed-Categories',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'category',
              },
            ],
          },
          {
            title: 'Brands',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'brand',
            children: [
              {
                title: 'Brands List',
                route: 'Brands-list',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'brand',
              },
              {
                title: 'Add New Brand',
                route: 'Add-Brand',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'brand',
              },
              {
                title: 'Sort Brands',
                route: 'Sort-Brands',
                customIcon: 'list-tree.svg',
                per: 'create',
                per2: 'brand',
              },
              {
                title: 'Brands Archived Data',
                route: 'Trashed-Brands',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'brand',
              },
            ],
          },
          {
            title: 'Tags',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'tag',
            children: [
              {
                title: 'Tags List',
                route: 'Tags-list',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'tag',
              },
              {
                title: 'Add New Tag',
                route: 'Add-Tag',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'tag',
              },
            ],
          },
          {
            title: 'Terms',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'term',
            children: [
              {
                title: 'Terms List',
                route: 'Terms-list',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'term',
              },
              {
                title: 'Add New Term',
                route: 'Add-Term',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'term',
              },
              {
                title: 'Terms Archived Data',
                route: 'Trashed-Terms',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'term',
              },
            ],
          },
          {
            title: 'Currencies',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'currency',
            children: [
              {
                title: 'Currencies List',
                route: 'Currencies',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'currency',
              },
              {
                title: 'Add New Currencies',
                route: 'AddCurrencies',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'currency',
              },
              {
                title: 'Currencies Archived Data',
                route: 'Trashed-Currencies',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'currency',
              },
            ],
          },
        ],
      },
      {
        title: 'Diagnostic Tool',
        customIcon: 'Diagnostics.svg',
        per: 'show',
        per2: 'diagnostic_list',
        children: [
          {
            title: 'Sensors',
            route: 'Sensors',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'sensor',
          },
          {
            title: 'Add Sensor',
            route: 'Sensor-Add',
            customIcon: 'plus.svg',
            per: 'create',
            per2: 'sensor',
          },
          {
            title: 'Sensors Archived Data',
            route: 'Sensors-Trashed',
            customIcon: 'list-tree.svg',
            per: 'show-trashed',
            per2: 'sensor',
          },

          {
            title: 'Reports Archived Data',
            route: 'Diagnostic-Reports-Trashed',
            customIcon: 'list-tree.svg',
            per: 'show-trashed',
            per2: 'diagnostic',
          },
        ],
      },
      {
        title: 'packages',
        customIcon: 'package-import.svg',
        per: 'packages',
        per2: 'show-packages',
        children: [
          {
            title: 'packages List',
            route: 'Show-packages',
            customIcon: 'list.svg',
            per: 'packages',
            per2: 'show-packages',
          },
          {
            title: 'Add package',
            route: 'Add-packages',
            customIcon: 'plus.svg',
            per: 'packages',
            per2: 'create-package',
          },
          {
            title: 'archive packages',
            route: 'trashed-packages',
            customIcon: 'list-tree.svg',
            per: 'show-trashed',
            per2: 'packages',
          },

        ],
      },
      {
        title: 'E-Commerce',
        customIcon: 'E-commerce.svg',
        per: 'show',
        per2: 'ecommerce',
        children: [
          {
            title: 'Coupons',
            customIcon: 'gift.svg',
            per: 'show',
            per2: 'coupon',
            children: [
              {
                title: 'Coupons List',
                route: 'Coupon-Index',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'coupon',
              },
              {
                title: 'Add New Coupon',
                route: 'Coupon-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'coupon',
              },
              {
                title: 'Coupons Archived Data',
                route: 'Coupon-Archived',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'coupon',
              },
            ],
          },
          {
            title: 'Offers',
            customIcon: 'gift-card.svg',
            per: 'show',
            per2: 'offer',
            children: [
              {
                title: 'Offers List',
                customIcon: 'list.svg',
                route: 'Offers-Index',
                per: 'show',
                per2: 'offer',
              },
              {
                title: 'Add New Offer',
                customIcon: 'plus.svg',
                route: 'Offer-Add',
                per: 'create',
                per2: 'offer',
              },
              {
                title: 'Offers Archived Data',
                customIcon: 'list-tree.svg',
                route: 'Offers-Trashed',
                per: 'show-trashed',
                per2: 'offer',
              },
            ],
          },
          {
            title: 'Attributes',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'attribute',
            children: [
              {
                title: 'Attributes List',
                route: 'Attributes-Index',
                customIcon: 'list-tree.svg',
                per: 'show',
                per2: 'attribute',
              },
              {
                title: 'Add New Attribute',
                route: 'Attributes-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'attribute',
              },
            ],
          },
          {
            title: 'Values',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'value',
            children: [
              {
                title: 'Values List',
                route: 'Values-Index',
                customIcon: 'list-tree.svg',
                per: 'show',
                per2: 'value',
              },
              {
                title: 'Add New Value',
                route: 'Values-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'value',
              },
            ],
          },
        ],
      },
      {
        title: 'Conditions',
        icon: 'CircleIcon',
        per: 'show',
        per2: 'condition',
        children: [
          {
            title: 'Conditions List',
            route: 'ConditionIndex',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'condition',
          },
          {
            title: 'Add New Condition',
            route: 'AddCondition',
            customIcon: 'plus.svg',
            per: 'create',
            per2: 'condition',
          },
        ],
      },


      {
        title: 'Margin Fees',
        icon: 'DollarSignIcon',
        per: 'show',
        per2: 'price_rate' || 'item_rate_price' || 'vendor_price_rate',
        children: [
          {
            title: 'Used Product',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'price_rate',
            children: [
              {
                title: 'Margin Fees List',
                route: 'Price-Rate',
                customIcon: 'list-tree.svg',
                per: 'show',
                per2: 'price_rate',
              },
              {
                title: 'Add Margin Fees',
                route: 'Price-Rate-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'price_rate',
              },
              {
                title: 'Margin Fees Archived Data',
                route: 'Price-Rate-Trashed',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'price_rate',
              },
            ],
          },
          {
            title: 'New Product',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'item_rate_price',
            children: [
              {
                title: 'Margin Fees List',
                route: 'New-Price-Rate',
                customIcon: 'list-tree.svg',
                per: 'show',
                per2: 'item_rate_price',
              },
              {
                title: 'Add Margin Fees',
                route: 'New-Price-Rate-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'item_rate_price',
              },
              {
                title: 'Margin Fees Archived Data',
                route: 'New-Price-Rate-Trashed',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'item_rate_price',
              },
            ],
          },
        ],
      },

      {
        title: 'Couriers',
        customIcon: 'package-import.svg',
        per: 'show',
        per2: 'courier',
        children: [
          {
            title: 'Couriers List',
            route: 'Courier-Index',
            customIcon: 'list.svg',
            per: 'show',
            per2: 'courier',
          },
          {
            title: 'Add New Courier',
            route: 'Courier-Add',
            customIcon: 'plus.svg',
            per: 'create',
            per2: 'courier',
          },
          {
            title: 'Courier Archived Data',
            route: 'Courier-Trashed',
            customIcon: 'list-tree.svg',
            per: 'show-trashed',
            per2: 'courier',
          },
        ],
      },


      {
        title: 'Locations',
        customIcon: 'Locations.svg',
        per: 'show',
        per2: 'country',
        children: [
          {
            title: 'Country',
            customIcon: 'map-pin.svg',
            per: 'show',
            per2: 'country',
            children: [
              {
                title: 'Countries List',
                route: 'Countries-Index',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'country',
              },
              {
                title: 'Add New Country',
                route: 'Countries-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'country',
              },
            ],
          },
          {
            title: 'City',
            customIcon: 'map-pin.svg',
            per: 'show',
            per2: 'city',
            children: [
              {
                title: 'Cities List',
                route: 'Cities-Index',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'city',
              },
              {
                title: 'Add New Cities',
                route: 'Cities-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'city',
              },
              {
                title: 'Cities Archived Data',
                route: 'Cities-Archived-Data',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'city',
              },
            ],
          },
          {
            title: 'Area',
            customIcon: 'map-pin.svg',
            per: 'show',
            per2: 'area',
            children: [
              {
                title: 'Areas List',
                route: 'Areas-Index',
                customIcon: 'list.svg',
                per: 'show',
                per2: 'area',
              },
              {
                title: 'Add New Areas',
                route: 'Areas-Add',
                customIcon: 'plus.svg',
                per: 'create',
                per2: 'area',
              },
              {
                title: 'Areas Archived Data',
                route: 'Areas-Archived-Data',
                customIcon: 'list-tree.svg',
                per: 'show-trashed',
                per2: 'area',
              },
            ],
          },
        ],
      },


      {
        title: 'Order Status',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'order-status',
        children: [
          {
            title: 'Status List',
            route: 'orderStatus',
            customIcon: 'list-tree.svg',
            per: 'show',
            per2: 'order-status',
          },
          {
            title: 'Add New Status',
            route: 'addorderStatus',
            customIcon: 'plus.svg',
            per: 'create',
            per2: 'order-status',
          },

          {
            title: 'Status Archived Data',
            route: 'Order-Trashed-Status',
            customIcon: 'list-tree.svg',
            per: 'show-trashed',
            per2: 'order-status',
          },
        ],
      },

      {
        title: 'Sellers Status',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'vendor_status',
        children: [
          {
            title: 'Status List',
            route: 'vendorStatus',
            customIcon: 'list-tree.svg',
            per: 'show',
            per2: 'vendor_status',
          },
          {
            title: 'Add New Status',
            route: 'addStatus',
            customIcon: 'plus.svg',
            per: 'create',
            per2: 'vendor_status',
          },
        ],
      },

      {
        title: 'Insurance Fees',
        customIcon: 'list.svg',
        per: 'show',
        per2: 'insurance_fees',
        children: [
          {
            title: 'Insurance Fees List',
            route: 'insurance-Rate',
            customIcon: 'list-tree.svg',
            per: 'show',
            per2: 'insurance_fees',
          },
          {
            title: 'Add Insurance Fees',
            route: 'insurance-Rate-Add',
            customIcon: 'plus.svg',
            per: 'create',
            per2: 'insurance_fees',
          },
          {
            title: 'Insurance Fees Archived Data',
            route: 'insurance-Rate-Trashed',
            customIcon: 'list-tree.svg',
            per: 'show-trashed',
            per2: 'insurance_fees',
          },
        ],
      },



    
    ]
  }

  // {
  //   title: 'Mobilemasr Website',
  //   route: 'Website',
  //   customIcon: 'Dashboard.svg',
  //   per: 'home',
  // },

  // {
  //   header:
  //     'ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  //   per: 'header',
  //   per2: 'home',
  // },
  // {
  //   title: 'Ads',
  //   icon: 'AlignJustifyIcon',
  //   per: 'show',
  //   per2: 'ads',
  //   children: [
  //     {
  //       title: 'List Ads',
  //       icon: 'AlignJustifyIcon',
  //       route: 'AdsIndex',
  //       per: 'show',
  //       per2: 'ads',


  //     },


  //   ],
  // },

  // {
  //   header:
  //     'ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  //   per: 'header',
  //   per2: 'ads',
  // },

  // {
  //   title: 'Blog',
  //   icon: 'AlignJustifyIcon',
  //   per: 'show',
  //   per2: 'post',
  //   children: [
  //     {
  //       title: 'Post Category',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'post_category',
  //       children: [
  //         {
  //           title: 'Post Category List',
  //           route: 'Blog-Post-Category-Index',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'post_category',
  //         },
  //         {
  //           title: 'Add Post Category',
  //           route: 'Blog-Post-Category-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'post_category',
  //         },
  //         {
  //           title: 'Post Category Archived Data',
  //           route: 'Blog-Post-Category-Trashed',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show-trashed',
  //           per2: 'post_category',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Posts',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'post',
  //       children: [
  //         {
  //           title: 'Posts List',
  //           route: 'Blog-Posts-Index',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'post',
  //         },
  //         {
  //           title: 'Add Post',
  //           route: 'Blog-Posts-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'post',
  //         },
  //         {
  //           title: 'Posts Archived Data',
  //           route: 'Blog-Post-Category-Trashed',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show-trashed',
  //           per2: 'post',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // // {
  // //   header:
  // //     'ــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  // //   per: 'header',
  // //   per2: 'post',
  // // },
  // {
  //   title: 'Administrations',
  //   customIcon: 'Admins.svg',

  //   per: 'show',
  //   per2: 'admin',
  //   children: [
  //     {
  //       title: 'Admins List',
  //       route: 'Admins-Index',
  //       customIcon: 'Admins.svg',
  //       per: 'show',
  //       per2: 'admin',
  //       // target: '_blank',
  //     },
  //     {
  //       title: 'Add New Admin',
  //       route: 'Register-Admin',
  //       icon: 'UserPlusIcon',
  //       per: 'create',
  //       per2: 'admin',
  //       // target: '_blank',
  //     },

  //     {
  //       title: 'Admins Archived Data',
  //       route: 'Admins-Trashed',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'admin',
  //       // target: '_blank',
  //     },
  //     {
  //       title: 'Roles',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'admin',
  //       children: [
  //         {
  //           title: 'Roles List',
  //           route: 'Roles-list',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'role',
  //         },
  //         {
  //           title: 'Add New Role',
  //           route: 'Add-Role',
  //           icon: 'PlusSquareIcon',
  //           per: 'create',
  //           per2: 'role',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Permissions',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'permission',
  //       children: [
  //         {
  //           title: 'Permissions List',
  //           route: 'Permissions-list',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'permission',
  //         },
  //         {
  //           title: 'Add New Permission',
  //           route: 'Add-Permission',
  //           icon: 'PlusSquareIcon',
  //           per: 'show',
  //           per2: 'create',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Users',
  //   customIcon: 'Users.svg',
  //   per: 'show',
  //   per2: 'order' || 'user',
  //   children: [
  //     {
  //       title: 'Users List',
  //       icon: 'AlignJustifyIcon',
  //       route: 'Users-Index',
  //       per: 'show',
  //       per2: 'user',
  //     },
  //     {
  //       title: 'Active Carts',
  //       icon: 'AlignJustifyIcon',
  //       route: 'Cart-Active',
  //       per: 'show',
  //       per2: 'order',
  //     },
  //     {
  //       title: 'Add New User',
  //       icon: 'UserPlusIcon',
  //       route: 'User-Add',
  //       per: 'create',
  //       per2: 'user',
  //     },
  //     {
  //       title: 'Users Archived Data',
  //       icon: 'Trash2Icon',
  //       route: 'Users-Trashed',
  //       per: 'show-trashed',
  //       per2: 'permission',
  //     },
  //   ],
  // },
  // {
  //   title: 'Sellers',
  //   customIcon: 'vendors.svg',
  //   per: 'show',
  //   per2: 'vendor',
  //   children: [
  //     {
  //       title: 'Sellers List',
  //       route: 'Vendor-Index',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'vendor',
  //     },
  //     {
  //       title: 'Add New Seller',
  //       route: 'Seller-Add',
  //       icon: 'UserPlusIcon',
  //       per: 'create-vendor',
  //       per2: 'vendor',
  //     },
  //     {
  //       title: 'Seller Archived List',
  //       route: 'Seller-Index-Trashed',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'vendor',
  //     },

  //     // {
  //     //   title: 'Dealers',
  //     //   icon: 'UsersIcon',
  //     //   per: 'show',
  //     //   per2: 'vendor',
  //     //   children: [
  //     //     {
  //     //       title: 'Dealers Index',
  //     //       route: 'Seller-Index',
  //     //       icon: 'AlignJustifyIcon',
  //     //       per: 'show',
  //     //       per2: 'vendor',
  //     //     },
  //     //     {
  //     //       title: 'Add New Dealer',
  //     //       route: 'Seller-Add',
  //     //       icon: 'UserPlusIcon',
  //     //       per: 'create-vendor',
  //     //       per2: 'vendor',
  //     //     },
  //     //     {
  //     //       title: 'Dealers Archived Data',
  //     //       route: 'Seller-Index-Trashed',
  //     //       icon: 'Trash2Icon',
  //     //       per: 'show-trashed',
  //     //       per2: 'vendor',
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   title: 'Agencies',
  //     //   icon: 'UsersIcon',
  //     //   per: 'show',
  //     //   per2: 'vendor',
  //     //   children: [
  //     //     {
  //     //       title: 'Agencies List',
  //     //       route: 'Agency-Index',
  //     //       icon: 'AlignJustifyIcon',
  //     //       per: 'show',
  //     //       per2: 'vendor',
  //     //     },
  //     //     {
  //     //       title: 'Add New Agency',
  //     //       route: 'Agency-Add',
  //     //       icon: 'UserPlusIcon',
  //     //       per: 'create-vendor',
  //     //       per2: 'vendor',
  //     //     },

  //     //     {
  //     //       title: 'Agencies Archived Data',
  //     //       route: 'Agency-Index-Trashed',
  //     //       icon: 'Trash2Icon',
  //     //       per: 'show-trashed',
  //     //       per2: 'vendor',
  //     //     },
  //     //   ],
  //     // },

  //     {
  //       title: 'Sellers Wallet Summary',
  //       route: 'Vendor-Wallets-Index',
  //       icon: 'DollarSignIcon',
  //       per: 'show',
  //       per2: 'vendor_wallet',
  //     },
  //     {
  //       title: 'Sellers Status',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'vendor_status',
  //       children: [
  //         {
  //           title: 'Status List',
  //           route: 'vendorStatus',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'vendor_status',
  //         },
  //         {
  //           title: 'Add New Status',
  //           route: 'addStatus',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'vendor_status',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   title: 'Payouts',
  //   route: 'Payouts-Index',
  //   icon: 'DollarSignIcon',
  //   per: 'show',
  //   per2: 'payouts',
  // },


  // // {
  // //   header:
  // //     'ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  // //   per: 'header',
  // //   per2: 'admin',
  // //   per3: 'user',
  // //   per4: 'vendor',
  // // },
  // {
  //   title: 'Accessories',
  //   customIcon: 'Accessories.svg',
  //   per: 'show',
  //   per2: 'accessory',
  //   children: [
  //     {
  //       title: 'Accessories List',
  //       route: 'Accessory-Index',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'accessory',
  //     },
  //     {
  //       title: 'Add Accessory',
  //       route: 'Accessory-Add',
  //       icon: 'PlusCircleIcon',
  //       per: 'create',
  //       per2: 'accessory',
  //     },
  //     {
  //       title: 'Accessories Archived Data',
  //       route: 'Accessory-Trashed',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'accessory',
  //     },
  //     // {
  //     //     title: 'Reports',
  //     //     route: 'Diagnostic-Reports',
  //     //     icon: "AlignJustifyIcon",
  //     //     per: 'show',
  //     //     per2: 'diagnostic',
  //     // },
  //     // {
  //     //     title: 'Reports Archived Data',
  //     //     route: 'Diagnostic-Reports-Trashed',
  //     //     icon: "Trash2Icon",
  //     //     per: 'show-trashed',
  //     //     per2: 'diagnostic',
  //     // },
  //   ],
  // },
  // {
  //   title: 'Diagnostic Tool',
  //   customIcon: 'Diagnostics.svg',
  //   per: 'show',
  //   per2: 'diagnostic',
  //   children: [
  //     {
  //       title: 'Sensors',
  //       route: 'Sensors',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'sensor',
  //     },
  //     {
  //       title: 'Add Sensor',
  //       route: 'Sensor-Add',
  //       icon: 'PlusCircleIcon',
  //       per: 'create',
  //       per2: 'sensor',
  //     },
  //     {
  //       title: 'Sensors Archived Data',
  //       route: 'Sensors-Trashed',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'sensor',
  //     },
  //     {
  //       title: 'Reports',
  //       route: 'Diagnostic-Reports',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'diagnostic',
  //     },
  //     {
  //       title: 'Reports Archived Data',
  //       route: 'Diagnostic-Reports-Trashed',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'diagnostic',
  //     },
  //   ],
  // },
  // // {
  // //   header:
  // //     'ــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  // //   per: 'header',
  // //   per2: 'diagnostic',
  // //   per3: 'accessory',
  // // },
  // {
  //   title: 'Classifications',
  //   customIcon: 'Classfications.svg',
  //   per: 'show',
  //   per2: 'classifications',

  //   children: [
  //     {
  //       title: 'Categories',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'category',
  //       children: [
  //         {
  //           title: 'Categories List',
  //           route: 'Categories-list',
  //           icon: 'PlusSquareIcon',
  //           per: 'show',
  //           per2: 'category',
  //         },
  //         {
  //           title: 'Add New Category',
  //           route: 'Add-Category',
  //           icon: 'PlusSquareIcon',
  //           per: 'create',
  //           per2: 'category',
  //         },
  //         {
  //           title: 'Categories Archived Data',
  //           route: 'Trashed-Categories',
  //           icon: 'PlusSquareIcon',
  //           per: 'show-trashed',
  //           per2: 'category',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Brands',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'brand',
  //       children: [
  //         {
  //           title: 'Brands List',
  //           route: 'Brands-list',
  //           icon: 'PlusSquareIcon',
  //           per: 'show',
  //           per2: 'brand',
  //         },
  //         {
  //           title: 'Add New Brand',
  //           route: 'Add-Brand',
  //           icon: 'PlusSquareIcon',
  //           per: 'create',
  //           per2: 'brand',
  //         },
  //         {
  //           title: 'Sort Brands',
  //           route: 'Sort-Brands',
  //           icon: 'PlusSquareIcon',
  //           per: 'create',
  //           per2: 'brand',
  //         },
  //         {
  //           title: 'Brands Archived Data',
  //           route: 'Trashed-Brands',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'brand',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Tags',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'tag',
  //       children: [
  //         {
  //           title: 'Tags List',
  //           route: 'Tags-list',
  //           icon: 'PlusSquareIcon',
  //           per: 'show',
  //           per2: 'tag',
  //         },
  //         {
  //           title: 'Add New Tag',
  //           route: 'Add-Tag',
  //           icon: 'PlusSquareIcon',
  //           per: 'create',
  //           per2: 'tag',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Terms',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'term',
  //       children: [
  //         {
  //           title: 'Terms List',
  //           route: 'Terms-list',
  //           icon: 'PlusSquareIcon',
  //           per: 'show',
  //           per2: 'term',
  //         },
  //         {
  //           title: 'Add New Term',
  //           route: 'Add-Term',
  //           icon: 'PlusSquareIcon',
  //           per: 'create',
  //           per2: 'term',
  //         },
  //         {
  //           title: 'Terms Archived Data',
  //           route: 'Trashed-Terms',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'term',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Currencies',
  //       icon: 'DollarSignIcon',
  //       per: 'show',
  //       per2: 'currency',
  //       children: [
  //         {
  //           title: 'Currencies List',
  //           route: 'Currencies',
  //           icon: 'DollarSignIcon',
  //           per: 'show',
  //           per2: 'currency',
  //         },
  //         {
  //           title: 'Add New Currencies',
  //           route: 'AddCurrencies',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'currency',
  //         },
  //         {
  //           title: 'Currencies Archived Data',
  //           route: 'Trashed-Currencies',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'currency',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "Buyer's Guide",
  //   customIcon: 'Buyer.svg',
  //   per: 'show',
  //   per2: 'phone_guide',
  //   children: [
  //     {
  //       title: 'Guides List',
  //       route: 'PhoneGuide',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'phone_guide',
  //     },
  //     {
  //       title: 'Guides Card View',
  //       route: 'PhoneGuideEcommerce',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'phone_guide',
  //     },
  //     {
  //       title: 'Create Item',
  //       route: 'CreateItem',
  //       icon: 'PlusCircleIcon',
  //       per: 'create-phone-guide',
  //       per2: 'phone_guide',
  //     },
  //     {
  //       title: 'Guides Archived Data',
  //       route: 'Trashed-PhoneGuide',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'phone_guide',
  //     },
  //     {
  //       title: 'Guides Family',
  //       route: 'Items-Parents',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'phone_guide',
  //     },
  //   ],
  // },
  // // {
  // //   header:
  // //     'ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  // //   per: 'header',
  // //   per2: 'classifications',
  // //   per3: 'phone_guide',
  // // },
  // {
  //   title: 'E-Commerce',
  //   customIcon: 'E-commerce.svg',
  //   per: 'show',
  //   per2: 'ecommerce',
  //   children: [
  //     {
  //       title: 'Coupons',
  //       icon: 'DollarSignIcon',
  //       per: 'show',
  //       per2: 'coupon',
  //       children: [
  //         {
  //           title: 'Coupons List',
  //           route: 'Coupon-Index',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'coupon',
  //         },
  //         {
  //           title: 'Add New Coupon',
  //           route: 'Coupon-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'coupon',
  //         },
  //         {
  //           title: 'Coupons Archived Data',
  //           route: 'Coupon-Archived',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'coupon',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Offers',
  //       icon: 'DollarSignIcon',
  //       per: 'show',
  //       per2: 'offer',
  //       children: [
  //         {
  //           title: 'Offers List',
  //           icon: 'AlignJustifyIcon',
  //           route: 'Offers-Index',
  //           per: 'show',
  //           per2: 'offer',
  //         },
  //         {
  //           title: 'Add New Offer',
  //           icon: 'PlusSquareIcon',
  //           route: 'Offer-Add',
  //           per: 'create',
  //           per2: 'offer',
  //         },
  //         {
  //           title: 'Offers Archived Data',
  //           icon: 'Trash2Icon',
  //           route: 'Offers-Trashed',
  //           per: 'show-trashed',
  //           per2: 'offer',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Attributes',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'attribute',
  //       children: [
  //         {
  //           title: 'Attributes List',
  //           route: 'Attributes-Index',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'attribute',
  //         },
  //         {
  //           title: 'Add New Attribute',
  //           route: 'Attributes-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'attribute',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Values',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'value',
  //       children: [
  //         {
  //           title: 'Values List',
  //           route: 'Values-Index',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'value',
  //         },
  //         {
  //           title: 'Add New Value',
  //           route: 'Values-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'value',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Products',
  //   customIcon: 'Products.svg',
  //   per: 'show',
  //   per2: 'product',
  //   children: [
  //     {
  //       title: 'New Products',
  //       icon: 'SmartphoneIcon',
  //       per: 'show',
  //       per2: 'product',
  //       children: [
  //         {
  //           title: 'Products List',
  //           route: 'NewProductIndex',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Add New Product',
  //           route: 'AddNewProduct',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Add In Store Product',
  //           route: 'AddNewProduct-InStore',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Products Card View',
  //           route: 'NewProductCardIndex',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Products Family List',
  //           route: 'NewProductFamilyIndex',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Products Archived Data',
  //           route: 'NewProductTrashed',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'product',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Used Products',
  //       icon: 'SmartphoneIcon',
  //       per: 'show',
  //       per2: 'product',
  //       children: [
  //         {
  //           title: 'Products List',
  //           route: 'ProductIndex',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Add Used Product',
  //           route: 'AddProduct',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Add In Store Product',
  //           route: 'AddProduct-InStore',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Add Unactive Product',
  //           route: 'AddUnactiveProduct',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Products Card View',
  //           route: 'ProductCardIndex',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Products Family List',
  //           route: 'ProductFamilyIndex',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'product',
  //         },
  //         {
  //           title: 'Products Archived Data',
  //           route: 'ProductTrashed',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'product',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Conditions',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'condition',
  //       children: [
  //         {
  //           title: 'Conditions List',
  //           route: 'ConditionIndex',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'condition',
  //         },
  //         {
  //           title: 'Add New Condition',
  //           route: 'AddCondition',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'condition',
  //         },
  //       ],
  //     },

  //     {
  //       title: 'Margin Fees',
  //       icon: 'DollarSignIcon',
  //       per: 'show',
  //       per2: 'price_rate' || 'item_rate_price' || 'vendor_price_rate',
  //       children: [
  //         {
  //           title: 'Used Product',
  //           icon: 'DollarSignIcon',
  //           per: 'show',
  //           per2: 'price_rate',
  //           children: [
  //             {
  //               title: 'Margin Fees List',
  //               route: 'Price-Rate',
  //               icon: 'AlignJustifyIcon',
  //               per: 'show',
  //               per2: 'price_rate',
  //             },
  //             {
  //               title: 'Add Margin Fees',
  //               route: 'Price-Rate-Add',
  //               icon: 'PlusCircleIcon',
  //               per: 'create',
  //               per2: 'price_rate',
  //             },
  //             {
  //               title: 'Margin Fees Archived Data',
  //               route: 'Price-Rate-Trashed',
  //               icon: 'Trash2Icon',
  //               per: 'show-trashed',
  //               per2: 'price_rate',
  //             },
  //           ],
  //         },
  //         {
  //           title: 'New Product',
  //           icon: 'DollarSignIcon',
  //           per: 'show',
  //           per2: 'item_rate_price',
  //           children: [
  //             {
  //               title: 'Margin Fees List',
  //               route: 'New-Price-Rate',
  //               icon: 'AlignJustifyIcon',
  //               per: 'show',
  //               per2: 'item_rate_price',
  //             },
  //             {
  //               title: 'Add Margin Fees',
  //               route: 'New-Price-Rate-Add',
  //               icon: 'PlusCircleIcon',
  //               per: 'create',
  //               per2: 'item_rate_price',
  //             },
  //             {
  //               title: 'Margin Fees Archived Data',
  //               route: 'New-Price-Rate-Trashed',
  //               icon: 'Trash2Icon',
  //               per: 'show-trashed',
  //               per2: 'item_rate_price',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   title: 'Bundles',
  //   customIcon: 'bundles.svg',
  //   per: 'show',
  //   per2: 'bundle',
  //   children: [
  //     {
  //       title: 'Bundles List',
  //       route: 'Bundles-Index',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'bundle',
  //     },
  //     {
  //       title: 'Add New Bundle',
  //       route: 'Bundle-Add',
  //       icon: 'PlusCircleIcon',
  //       per: 'create',
  //       per2: 'bundle',
  //     },
  //     {
  //       title: 'Bundles Archived Data',
  //       route: 'Bundles-Trashed',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'bundle',
  //     },
  //   ],
  // },

  // // {
  // //   header:
  // //     'ــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  // //   per: 'header',
  // //   per2: 'order',
  // //   per3: 'order',
  // // },
  // {
  //   title: 'Orders',
  //   customIcon: 'Orders.svg',
  //   per: 'show',
  //   per2: 'order',
  //   children: [
  //     {
  //       title: 'Orders List',
  //       route: 'OrderIndex',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'order',
  //     },
  //     // {
  //     //   title: 'Add New Order',
  //     //   route: 'CreateOrder',
  //     //   icon: 'PlusCircleIcon',
  //     //   per: 'create',
  //     //   per2: 'order',
  //     // },
  //     {
  //       title: 'Orders Archived Data',
  //       route: 'OrderTrashed',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'order',
  //     },


  //     {
  //       title: 'Status',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'order-status',
  //       children: [
  //         {
  //           title: 'Status List',
  //           route: 'orderStatus',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'order-status',
  //         },
  //         {
  //           title: 'Add New Status',
  //           route: 'addorderStatus',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'order-status',
  //         },

  //         {
  //           title: 'Status Archived Data',
  //           route: 'Order-Trashed-Status',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'order-status',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   title: 'Bidding',
  //   customIcon: 'Orders.svg',
  //   per: 'show',
  //   per2: 'bidding',
  //   children: [
  //     {
  //       title: 'Bidding List',
  //       route: 'biddings-request-index',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'bidding',
  //     },
  //     {
  //       title: 'Bidding Archived List',
  //       route: 'biddings-request-archived-index',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'bidding',
  //     },


  //   ],
  // },
  // {
  //   title: 'Payment Sessions',
  //   customIcon: 'Orders.svg',
  //   per: 'show',
  //   per2: 'payment-session',
  //   children: [
  //     {
  //       title: 'Payment Sessions List',
  //       route: 'paymentSession-index',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'payment-session',
  //     },
  //     {
  //       title: 'Payment Sessions Archived List',
  //       route: 'paymentSession-trashed-index',
  //       icon: 'Trash2Icon',
  //       per: 'show-trashed',
  //       per2: 'payment-session',
  //     },

  //   ],
  // },

  // {
  //   title: 'Transactions',
  //   customIcon: 'Orders.svg',
  //   per: 'show',
  //   per2: 'payment',
  //   children: [
  //     {
  //       title: 'Transaction List',
  //       route: 'Transaction',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'payment',
  //     },
  //   ],
  // },
  // {
  //   title: 'Refund',
  //   customIcon: 'Orders.svg',
  //   per: 'show',
  //   per2: 'refund',
  //   children: [
  //     {
  //       title: 'Refund List',
  //       route: 'Refund-Index',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show',
  //       per2: 'refund',
  //     },
  //   ],
  // },

  // // {
  // //   header:
  // //     'ــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  // //   per: 'header',
  // //   per2: 'ecommerce',
  // //   per3: 'product',
  // //   per4: 'bundle',
  // //   per5: 'order',
  // //   per6: 'refund',
  // // },


  // {
  //   title: 'Shipping',
  //   customIcon: 'Orders.svg',
  //   per: 'show',
  //   per2: 'air-way-bill' || 'courier',
  //   children: [
  //     {
  //       title: 'AirWayBill',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'air-way-bill',
  //       children: [
  //         {
  //           title: 'AirWayBill List',
  //           route: 'IndexAirWayBill',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'air-way-bill',
  //         },
  //         {
  //           title: 'Create Pickup Request',
  //           route: 'CreateAirWayBill',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'create-air-way-bill',
  //         },

  //       ],
  //     },
  //     {
  //       title: 'Couriers',
  //       customIcon: 'Couriers.svg',
  //       per: 'show',
  //       per2: 'courier',
  //       children: [
  //         {
  //           title: 'Couriers List',
  //           route: 'Courier-Index',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show',
  //           per2: 'courier',
  //         },
  //         {
  //           title: 'Add New Courier',
  //           route: 'Courier-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'courier',
  //         },
  //         {
  //           title: 'Courier Archived Data',
  //           route: 'Courier-Trashed',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'courier',
  //         },
  //       ],
  //     },

  //   ],
  // },



  // {
  //   title: 'Locations',
  //   customIcon: 'Locations.svg',
  //   per: 'show',
  //   per2: 'country',
  //   children: [
  //     {
  //       title: 'Country',
  //       icon: 'MapPinIcon',
  //       per: 'show',
  //       per2: 'country',
  //       children: [
  //         {
  //           title: 'Countries List',
  //           route: 'Countries-Index',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'country',
  //         },
  //         {
  //           title: 'Add New Country',
  //           route: 'Countries-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'country',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'City',
  //       icon: 'MapPinIcon',
  //       per: 'show',
  //       per2: 'city',
  //       children: [
  //         {
  //           title: 'Cities List',
  //           route: 'Cities-Index',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'city',
  //         },
  //         {
  //           title: 'Add New Cities',
  //           route: 'Cities-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'city',
  //         },
  //         {
  //           title: 'Cities Archived Data',
  //           route: 'Cities-Archived-Data',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'city',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Area',
  //       icon: 'MapPinIcon',
  //       per: 'show',
  //       per2: 'area',
  //       children: [
  //         {
  //           title: 'Areas List',
  //           route: 'Areas-Index',
  //           icon: 'CircleIcon',
  //           per: 'show',
  //           per2: 'area',
  //         },
  //         {
  //           title: 'Add New Areas',
  //           route: 'Areas-Add',
  //           icon: 'PlusCircleIcon',
  //           per: 'create',
  //           per2: 'area',
  //         },
  //         {
  //           title: 'Areas Archived Data',
  //           route: 'Areas-Archived-Data',
  //           icon: 'Trash2Icon',
  //           per: 'show-trashed',
  //           per2: 'area',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // // {
  // //   header:
  // //     'ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  // //   per: 'header',
  // //   per2: 'courier',
  // //   per3: 'country',
  // // },


  // {
  //   title: 'Top Products',
  //   icon: 'AlignJustifyIcon',
  //   route: 'top-products',
  //   per: 'show',
  //   per2: 'variant',
  // },
  // {
  //   title: 'Activity Log',
  //   customIcon: 'Setting.svg',

  //   route: 'Activity-Log',
  //   per: 'show',
  //   per2: 'activity_log',
  // },
  // {
  //   title: 'Settings',
  //   customIcon: 'Setting.svg',
  //   per2: 'setting',
  //   per: 'show',
  //   children: [


  //     {
  //       title: 'Payment Provider',
  //       route: 'PaymentProviders-Index',
  //       icon: 'AlignJustifyIcon',
  //       per: 'payment-providers',
  //       per2: 'show',
  //     },
  //     {
  //       title: 'Settings List',
  //       route: 'Settings-Index',
  //       icon: 'AlignJustifyIcon',
  //       per: 'update',
  //       per2: 'setting',
  //     },
  //     {
  //       title: 'Add New Setting',
  //       route: 'Settings-Add',
  //       icon: 'PlusCircleIcon',
  //       per: 'create_setting',
  //       per2: 'general_setting',
  //     },


  //   ],
  //   // route: 'Settings',
  // },
  // {
  //   title: 'Company Financial Account',
  //   route: 'Company-Financial-Account',
  //   customIcon: 'Company_Financial.svg',
  //   per: 'show',
  //   per2: 'financial',
  // },
  // {
  //   title: 'Problems',
  //   route: 'Problems-Index',
  //   icon: 'BarChart2Icon',
  //   per: 'show',
  //   per2: 'problem',
  // },
  // {
  //   title: 'Accounting Reports',
  //   customIcon: 'AccountReport.svg',
  //   per: 'show',
  //   per2: 'report',
  //   children: [
  //     {
  //       title: 'Sales Report',
  //       route: 'Sales Report',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'sales_report',
  //     },
  //     {
  //       title: 'Sales Report Transaction Level',
  //       route: 'Sales Report Transaction Level',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'sales_report',
  //     },
  //     {
  //       title: 'Cash Collection',
  //       route: 'Cash-Collection',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Out For Delivery',
  //       route: 'Out-For-Delivery',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Settled Sales',
  //       route: 'Settled-Sales',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Vendor Payout',
  //       route: 'Vendor-Payout',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Sales',
  //       route: 'Total-Sale',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Billing',
  //       route: 'Billing-Report',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Revenu',
  //       route: 'Revenu-Report',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Stock',
  //       route: 'Stock-Report',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'User Orders',
  //       route: 'User-Orders-Report',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //     {
  //       title: 'Vendor Orders',
  //       route: 'Vendor-Orders-Report',
  //       icon: 'CircleIcon',
  //       per: 'show',
  //       per2: 'report',
  //     },
  //   ],
  // },
  // {
  //   title: 'Archived Products Main Table',
  //   customIcon: 'Setting.svg',
  //   route: 'products-table-archived',
  //   per: 'show-trashed',
  //   per2: 'variant',
  // },
  // {
  //   title: 'Archived Ads Main Table',
  //   customIcon: 'Setting.svg',
  //   route: 'ads-table-archived',
  //   per: 'show-trashed',
  //   per2: 'variant',
  // },

  // {
  //   title: 'newsletters',
  //   customIcon: 'Orders.svg',
  //   per: 'newsletters',
  //   per2: 'variant',
  //   children: [
  //     {
  //       title: 'Send newsletters',
  //       route: 'Send-newsletters',
  //       icon: 'AlignJustifyIcon',
  //       per: 'newsletters',
  //       per2: 'send-newsletters',
  //     },
  //     {
  //       title: 'Show newsletters',
  //       route: 'Show-newsletters',
  //       icon: 'AlignJustifyIcon',
  //       per: 'newsletters',
  //       per2: 'show-newsletters',
  //     },
  //   ],
  // },



  // {
  //   title: 'Subscriptions',
  //   customIcon: 'Orders.svg',
  //   per: 'subscriptions',
  //   per2: 'subscriptions',
  //   children: [

  //     {
  //       title: 'Subscriptions List',
  //       route: 'index-subscriptions',
  //       icon: 'AlignJustifyIcon',
  //       per: 'subscriptions',
  //       per2: 'show-subscriptions',
  //     },
  //     {
  //       title: 'archive Subscriptions',
  //       route: 'trashed-subscriptions',
  //       icon: 'AlignJustifyIcon',
  //       per: 'show-trashed',
  //       per2: 'subscriptions',
  //     },
  //     {
  //       title: 'packages',
  //       customIcon: 'Couriers.svg',
  //       per: 'packages',
  //       per2: 'show-packages',
  //       children: [
  //         {
  //           title: 'packages List',
  //           route: 'Show-packages',
  //           icon: 'AlignJustifyIcon',
  //           per: 'packages',
  //           per2: 'show-packages',
  //         },
  //         {
  //           title: 'Add package',
  //           route: 'Add-packages',
  //           icon: 'AlignJustifyIcon',
  //           per: 'packages',
  //           per2: 'create-package',
  //         },
  //         {
  //           title: 'archive packages',
  //           route: 'trashed-packages',
  //           icon: 'AlignJustifyIcon',
  //           per: 'show-trashed',
  //           per2: 'packages',
  //         },

  //       ],
  //     },
  //   ],
  // },


  // {
  //   header:
  //     'ــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ',
  //   per: 'header',
  //   per2: 'operation-actions',
  // },

  // Heigh Level Permissions
  // {
  //   title: 'Operation Actions',
  //   customIcon: 'AccountReport.svg',
  //   per: 'show',
  //   per2: 'operation-actions',
  //   children: [
  //     {
  //       title: 'Order Actions',
  //       route: 'Operation-Order-Action',
  //       icon: 'CircleIcon',
  //       per: 'operation-actions',
  //       per2: 'operation-order-actions',
  //     },
  //     {
  //       title: 'Product Actions',
  //       route: 'Operation-Variant-Action',
  //       icon: 'CircleIcon',
  //       per: 'operation-actions',
  //       per2: 'operation-product-actions',
  //     },
  //     {
  //       title: 'Refund Actions',
  //       route: 'Operation-Refund-Action',
  //       icon: 'CircleIcon',
  //       per: 'operation-actions',
  //       per2: 'operation-refund-actions',
  //     },
  //     {
  //       title: 'Diagnostic Actions',
  //       route: 'Operation-Diagnostic-Action',
  //       icon: 'CircleIcon',
  //       per: 'operation-actions',
  //       per2: 'operation-diagnostic-actions',
  //     },

  //   ],
  // },
]
